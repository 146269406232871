import axios from 'axios';
import { CookieJar } from 'tough-cookie';
import values from './index';

const cookieJar = new CookieJar();


const axiosInstance = axios.create({
    baseURL: 'https://www.hashcash.tech/api/', 
    withCredentials: true,
    jar: cookieJar
})

axiosInstance.interceptors.request.use((config) => {
    const token = values.store.getState().auth?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

export default axiosInstance;