
import {useCallback, useEffect, useState} from 'react';import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import '../assets/css/Plans.css';
import axiosInstance from '../redux/axios';
import PlanPurchasePopUp from './PlanPurchasePopUp';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const plans = [
    {id: 0, init: 0, price: 132 , miner: 'XMR Miner X3', img: '/static/machines/x5.png', name: 'x0.5', pack: 'Common', icon: SettingsInputSvideoIcon, color: 'rgb(9 93 57)', di: 1.10, wi: 4.76, ti: 20.42},
    {id: 1, init: 0, price: 282 , miner: 'XMR Miner X5', img: '/static/machines/x5.png', name: 'x1', pack: 'Standard', icon: SettingsInputSvideoIcon, color: '#647D97', di: 0.68, wi: 4.76, ti: 20.42},
    {id: 2, init: 7, price: 564 , miner: 'HNS Miner HS3', img: '/static/machines/HS3.png' ,name: 'x2', pack: 'Gold', icon: LeaderboardOutlinedIcon, color: '#DC8707', di: 1.36, wi: 9.53, ti: 40.83},
    {id: 3, init: 14, price: 1690 ,miner: 'MICROBT WhatsMiner M50S++', img: '/static/machines/M50S.png', name: 'x6', pack: 'Platinum', icon: TokenOutlinedIcon, color: '#0094FF', di: 4.08, wi: 28.58, ti: 122.5},
    {id: 4, init: 21, price: 2180 ,miner: 'Zcash Miner Z15 Pro', img: '/static/machines/Z15.png', name: 'x20', pack: 'Diamond', icon: DiamondOutlinedIcon, color: '#30D891', di: 13.61, wi: 95.28, ti: 408.35},
    {id: 5, init: 28, price: 2780 ,miner: 'Miner T21', img: '/static/machines/T21.png', name: 'x30', pack: 'VIP1', icon: StarBorderOutlinedIcon, color: '#F36EFF', di: 20.42, wi: 142.92, ti: 612.52},
    {id: 6, init: 35, price: 3150 ,miner: 'ETC Miner E9 Pro', img: '/static/machines/e9.png', name: 'x60', pack: 'VIP2', icon: StarHalfOutlinedIcon, color: '#D4A70C', di: 40.83, wi: 285.84, ti: 1225.05},
    {id: 7, init: 42, price: 6620 ,miner: 'Miner S21 Hyd.', img: '/static/machines/S21.png', name: 'x280', pack: 'VIP3', icon: StarOutlinedIcon, color: '#FF7C74', di: 190.56, wi: 1333.94, ti: 5716.89},
]

export default function PlansComponent(){
    const [miners, setMiners] = useState([]);
    const [openModal, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const {user} = useSelector(state => state.auth);
    const navigate = useNavigate();


    const getAllMinerst = useCallback(
      async () => {
        try{
            const result = await axiosInstance.get('minerst');
        
            if(result.data){
                setMiners(result.data);
            }
        }catch{}
      },
      [],
    )
    
    useEffect(() => {
        getAllMinerst();
    }, [getAllMinerst])
    

    const checkStarted = (x) => {
        if(user){
            setSelectedPlan(x);
            setOpen(true);
        }else{
            navigate('/register')
        }
    }

    return(
        <>
            <Grid container spacing={5}>
                {miners && miners.slice().reverse().map((x,i) =>
                    <Grid item key={x._id} xs={12} sm={6} md={4}>
                        <div className="plan_item contact_form">
                            <div className="plan_h1" style={{color: plans[i].color}}>{x.name}</div>
                            <div className="plan_l">{plans[i].pack}</div>

                            <Divider/>

                            <div className="plan_price" style={{color: plans[i].color}}>{x.price}<span>/USDC</span></div>
                            <Button style={{width: '100%'}} onClick={() => {checkStarted(x)}} className="btn_about mt-0 mb-3">Get Started</Button>

                            <Divider/>

                            <div className="plan_features">
                                <div>
                                    <img src={'/static/machines/'+x.imageurl+'.png'} alt='mach' />
                                </div>
                                <div><CheckCircleOutlineOutlinedIcon style={{color: plans[i].color}}/>Hash Rate : {plans[i].name}</div>
                                <div><CheckCircleOutlineOutlinedIcon style={{color: plans[i].color}}/>Plan Price : {x.price} USDC</div>
                                <div><CheckCircleOutlineOutlinedIcon style={{color: plans[i].color}}/>Daily income: {x.type} USDC</div>
                                <div><CheckCircleOutlineOutlinedIcon style={{color: plans[i].color}}/>Weekly income: {(x.type * 7).toFixed(2)} USDC</div>
                                <div><CheckCircleOutlineOutlinedIcon style={{color: plans[i].color}}/>Monthly income: {(x.type * 30).toFixed(2)} USDC</div>
                            </div>
                        </div>
                    </Grid>
                )}
            </Grid>
            <PlanPurchasePopUp open={openModal} setOpen={setOpen} plan={selectedPlan} setSelectedPlan={setSelectedPlan} />
        </>
    )
}