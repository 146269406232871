import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import axiosInstance from '../../redux/axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateTrialClaimed } from '../../redux/reducers/authReducer';
import { useDispatch} from 'react-redux';

export default function ClaimTrial({user, getMiners}){
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('');
    const dispatch = useDispatch();

    const handleClose = () => {
      setOpen(false);
    };
    const handleClose1 = () => {
        setText('');
      };

    const claimMiner = async () => {
        setOpen(true);
        try{
            let data = {
                tx: '0xtrial',
                ownerId: user?._id,
                type: 2
            }
            const response = await axiosInstance.post('miners-trial',data);
           
            if(response.data){
                setText('Miner claimed successfully');
                getMiners();
                dispatch(updateTrialClaimed());
            }
            setOpen(false);
        }catch(err) {
            if(err?.response?.data){
                setText(err.response.data)
            }
            setOpen(false);
        }
    }

    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose1}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    return(
        <Paper className="paper_card pt-3 pb-3 mb-5 ps-3 pe-3" variant="elevation" elevation={6}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={text !== ''}
                autoHideDuration={6000}
                onClose={handleClose1}
                message={text}
                action={action}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <h3>Claim Free Trial</h3>
                    <p>Claim XMR Miner X5 (standard) miner for a period of 4 days to experience the workflow and earnings.</p>
                    <Button onClick={() => claimMiner()} variant="contained" style={{color: 'white'}}>Claim Now</Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{width: '10rem'}} src={'/static/machines/XMR Miner X5.png'} alt='mach' />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}