import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import axiosInstance from '../../redux/axios';

const methods = [
    {id: 0, name: 'Withdraw USDC ERC20 Base'}
]

export default function Withdraw({user}){
    const [gateaway, setGateaway] = useState("");
    const [amount, setAmount] = useState(0);
    const [wallet, setWallet] = useState("");

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        setError("");
    }, [gateaway, amount, wallet])
    

    const validate = () => {
        return gateaway !== '' && gateaway !== null && amount !== "" && amount > 0 && wallet !== ""
    }
    const submit = async (e) => {
        e.preventDefault();

        if(validate()){
            if(amount <= user?.balance){
                const data ={
                    tx: gateaway,
                    wallet: wallet,
                    amount: amount
                }
                try{
                    const response = await axiosInstance.post('withdraw/request/'+amount, data);
                    if(response.data){
                        setSuccess('Request send. Wait for confirmation');
                    }
                }catch{
                    setError("Not enough funds");
                }
            }else{
                setError("Insufficient balance")
            }
        }else{
            setError('Fill in the form');
        }
    }
    return(
        <div className="dashboard_page">
            <Container maxWidth="sm">
                <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                    <h4>Withdraw Money</h4>
                    <p>Balance: {user?.balance} USDC</p>
                        <Form className="pt-4 pb-3">
                            <Form.Group className="mb-3" controlId="withdrawForm.gateway">
                                <Form.Label>Method <span>*</span></Form.Label>
                                <Form.Select value={gateaway} onChange={(e) => setGateaway(e.target.value)} aria-label="countries">
                                    <option value="Select Getaway">Select Getaway</option>
                                    {methods.map(m =>
                                        <option key={m.id} value={m.name}>{m.name}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="withdrawForm.amount">
                                <Form.Label>Amount <span>*</span></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control value={amount} onChange={(e) => setAmount(e.target.value)} type="number" placeholder="" />
                                    <InputGroup.Text>USDC</InputGroup.Text>
                                </InputGroup>  
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="withdrawForm.wallet">
                                <Form.Label>Wallet <span>*</span></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control value={wallet} onChange={(e) => setWallet(e.target.value)} type="string" placeholder="0xaddress" />
                                </InputGroup>  
                            </Form.Group>
                            <div className="display_start">
                                <Button type="submit" onClick={(e) => submit(e)} className="btn_about full-width">Submit</Button>
                            </div>
                            {error && <p className="text_error">{error}</p>}
                            {success && <p className="text_success">{success}</p>}
                        </Form>
                </Paper>
            </Container>
        </div>
    )
}