import {Container} from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axiosInstance from '../../redux/axios';
import AdminConfirm from '../../components/AdminConfirm';

const columnsUser = [
    { field: '_id', headerName: 'ID', width: 130 },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'email', headerName: 'Email', width: 100 },
    {
      field: 'telegram',
      headerName: 'Telegram',
      width: 150,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 120,
    },
    {
        field: 'ref_code',
        headerName: 'Ref Code',
        width: 130,
      },
      {
        field: 'ref_by',
        headerName: 'Ref By',
        width: 130,
      },
      {
        field: 'country',
        headerName: 'Country',
        width: 130,
      },
  ];
  


export default function AdminUsers(){
    const [open, setOpen] = React.useState(false);
    const [w, setSelectedW] = React.useState(null);

    const [users, setUsers] = React.useState([]);
    const [withdraws, setWithdraws] = React.useState([]);
    const [selected, setSelected] = React.useState('users');
    
    const columnsWithdraws =[
      { field: 'tx', headerName: 'Gateway | Transaction', width: 250 },
      { field: 'date', headerName: 'Initiated', width: 200 },
      { field: 'amount', headerName: 'Amount', width: 130, type: 'number' },
      {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: (params) => {
          const cellStyle = {
            color: params.value === 'pending' ? 'gray' : 'green',
          };
          return <div style={cellStyle}>{params.value}</div>;
        },
      },
      {
        field: 'hash',
        headerName: 'Hash',
        width: 200,
      },
      {
          field: 'actions',
          headerName: 'Actions',
          width: 150,
          renderCell: (params) => (
            <>
            {params.row.status === "pending" &&
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setSelectedW(params.row);
                }}
              >
                Accept
              </Button>
              }
            </>
          ),
      },
    ];

    const UpgratePackage = (p) => {
      setWithdraws(withdraws =>
        withdraws.map(obj => 
          obj._id === p._id ? p : obj
        )
      );
    }

    const getUsers = React.useCallback(
      async () => {
        try{
            const response = await axiosInstance.get('users')
            if(response.data){
              setUsers(response.data)
            }
        }catch{}
      },
      [],
    )

    const getWithdraws = React.useCallback(
        async () => {
          try{
              const response = await axiosInstance.get('withdraws');
              if(response.data){
                setWithdraws(response.data)
              }
          }catch{}
        },
        [],
      )
    
    React.useEffect(() => {
        getUsers()
        getWithdraws()
    },[getUsers, getWithdraws])

    return(
        <div className="home_page pt-5">
             <AdminConfirm open={open} setOpen={setOpen} w={w} setSelectedW={setSelectedW} UpgratePackage={UpgratePackage}/>
             <div className="pb-4" style={{display: 'flex', justifyContent: 'center'}}>
                <ButtonGroup size="small" aria-label="Toggle-tutorial" className="toggle_tutorial">
                    <Button onClick={() => setSelected('users')} variant={selected === 'users' ? 'contained': 'outlined'}>Users</Button>
                    <Button onClick={() => setSelected('withdraws')} variant={selected === 'withdraws' ? 'contained': 'outlined'}>Withdraw Requests</Button>
                </ButtonGroup>
            </div>
            {selected === 'users' &&
            <Container>
                <h3 className="pb-4 text-center">Users Preview</h3>

                <div style={{ overflow: 'scroll', maxWidth: '80vw'}}>
                    <DataGrid
                        getRowId={(row: any) =>  row._id + row.username}
                        rows={users}
                        columns={columnsUser}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        pageSizeOptions={[5, 10]}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                        }}
                        autoHeight
                    />
                    </div>
            </Container>
            }
            {selected === 'withdraws' &&
            <Container>
                <h3 className="pb-4 text-center">Withdraws Preview</h3>

                <div style={{ overflow: 'scroll', maxWidth: '80vw'}}>
                    <DataGrid
                        getRowId={(row: any) =>  row.date + row.amount}
                        rows={withdraws}
                        columns={columnsWithdraws}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        pageSizeOptions={[5, 10]}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                        }}
                        autoHeight
                    />
                    </div>
            </Container>
            }
        </div>
    )
}