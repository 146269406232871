import Grid from '@mui/material/Grid';
import DCard from '../../components/dashboard/DCards';
import { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CellWifiIcon from '@mui/icons-material/CellWifi';

import MinerView from '../../components/dashboard/Miner';
import axiosInstance from '../../redux/axios';
import { updateUserMine } from '../../redux/reducers/authReducer';
import { useDispatch} from 'react-redux';
import ClaimTrial from '../../components/dashboard/ClaimTrial';

import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Terminal from '../../components/dashboard/Terminal';
import { logOutAuth } from '../../redux/reducers/authReducer';
import { useNavigate } from 'react-router-dom';


export default function Dashboard({user}){
  const navigate = useNavigate();
  const [minersUser, setMiners] = useState([]);
  const [earningsTodays, setEarningsToday] = useState(0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [copied, setCopied] = useState(false);


  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const targetDate = new Date(user?.lastmined);
    targetDate.setHours(targetDate.getHours() + 24);
    
    const difference = targetDate - now;
    const timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };

    return timeLeft;
  },[user])
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
      setText('');
    };

    const getMiners = useCallback(
      async () => {
        try{
          const response = await axiosInstance.get('miners');
          
          if(response.data){
            setMiners(response.data);
          }
        }catch(err){
          if(err.message === "Request failed with status code 401"){    
            dispatch(logOutAuth())
            navigate('/login');
          }
      }
      },
      [dispatch, navigate],
    )

    useEffect(() => {
        getMiners();
    },[getMiners])
   

    const getUntil = (created_at, days_rent) => {
      try{
          return new Date(new Date(created_at).getTime() + Number(days_rent) * 24 * 60 * 60 * 1000).toISOString()
      }catch{
          return created_at;
      }
    }


    function isTodayOrFuture(date) {
        const now = new Date();
        const givenDate = new Date(date);
    
        // Reset the time part of the dates to compare only the date part
        now.setHours(0, 0, 0, 0);
        givenDate.setHours(0, 0, 0, 0);
    
        return givenDate > now;
    }

     const has24HoursPassed = useCallback(() => {
        const storedTime = new Date(user?.lastmined).getTime();
        const currentTime = new Date().getTime(); // Get the current time in milliseconds
        const hours24 = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

        // Compare the difference in time
        return (currentTime - storedTime) >= hours24;
    }, [user])

    const checkEarningsToday = useCallback(() => {
      if(!has24HoursPassed() && isTodayOrFuture(user?.lastmined)){
        if(minersUser.length > 0){
          let amount = 0;
          minersUser.forEach(miner => {
            const until = getUntil(miner.created_at, miner.days_rent);
           
            if(isTodayOrFuture(until)){
              amount += miner.type;
            }
          })

          setEarningsToday(amount);
        }else{
          setEarningsToday(0)
        }
      }else{
        setEarningsToday(0)
      }
    }, [has24HoursPassed, minersUser, user]);

    
    useEffect(() => {
      checkEarningsToday()
    },[checkEarningsToday])

    const updateMine = useCallback(async () => {
      setOpen(true);
      try{
        const response = await axiosInstance.post('/user/mine');
        if(response.status === 201){
          if(response?.data?.success){
            getMiners();
            checkEarningsToday();
            setText('Rewards claimed successfully');
            
            dispatch(updateUserMine({balance: response?.data?.balance, lastmined: response?.data?.lastminednow}))
          }
        }
        setOpen(false);
      }catch(err){
        setOpen(false);
        if(err?.response?.data){
          setText(err?.response?.data)
        }
      }
    }, [dispatch, getMiners,checkEarningsToday])

    const action = (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose1}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    );

    const copyAddress = (val) => {
      try{
          const textarea = document.createElement("textarea");
          textarea.value = val;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);

          setCopied(true);

          setTimeout(() => {
              setCopied(false);
          }, 1000)
      }catch{}
        
  }
     
    return(
        <div className="dashboard_page">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={text !== ''}
                autoHideDuration={6000}
                onClose={handleClose1}
                message={text}
                action={action}
            />
            {user?.trial_claimed === 0 &&
              <ClaimTrial user={user} getMiners={getMiners} />
            }

            {minersUser?.length > 0 && has24HoursPassed() && 
              <Button className="mb-5 mt-0 btn_about" onClick={() => updateMine()}>Claim Reward</Button>
            }

            {minersUser?.length > 0 && !has24HoursPassed() && timeLeft &&
              <p className="mb-5 mt-0 btn_about" style={{width: 'fit-content'}} >Next reward in: {`${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}</p>
            }


            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}><DCard label="Total Balance" value={"$"+user?.balance?.toLocaleString()} icon={<AccountBalanceWalletIcon/>} background="linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))"/></Grid>
                {/** 
                <Grid item xs={12} sm={6} md={4}><DCard label="Earnings Today" gross={"$"+(earningsTodays > 0 ? (earningsTodays + earningsTodays * 0.1).toFixed(2) : earningsTodays)} value={"$"+earningsTodays?.toLocaleString()} icon={<CurrencyExchangeIcon/>} background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))"/></Grid>
                */}
                <Grid item xs={12} sm={6} md={6}><DCard label="Total Miners" value={minersUser ? minersUser.length : 1} icon={<CellWifiIcon/>} background="linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))" /></Grid>
            </Grid>
            
            <Grid container className="mt-3" spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                      <h5 style={{fontWeight: '600'}}>Referral Code</h5>
                      <p>Get 10% of user's miners registered through your link.</p>
                      <InputGroup hasValidation>    
                        <InputGroup.Text onClick={() => copyAddress("https://hashcash.finance/register/"+user?.ref_code)} style={{cursor: 'pointer'}}>{copied ? <DoneIcon style={{color: 'green'}}/> : <ContentCopyIcon/>}</InputGroup.Text>
                        <Form.Control type="string" disabled value={"https://hashcash.finance/register/"+user?.ref_code} placeholder="" />
                      </InputGroup>
                    </Paper>
                </Grid>
              </Grid>

            <Grid container className="mt-3" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                        {/*<canvas  id="acquisitions"></canvas>*/}
                        <Terminal workIf={minersUser?.length > 0 } />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper className="paper_card pt-3 pb-3 ps-3 pe-3 full-height" variant="elevation" elevation={6}>
                        <h4 className="pb-4">My Miners</h4>
                        <div style={{flex: 1}} className="display_center">
                            {minersUser.length === 0 &&
                                <div className="display_center">
                                    <p>You don't own any miners.</p>
                                    <Button onClick={() => window.open('/plans', '_blank')} variant="outlined" className="me-3 btn_user"><CellWifiIcon className="me-2" /> Rent Miners</Button>
                                </div>
                            }
                            {minersUser.length > 0 &&
                                <Grid container spacing={2}>
                                {minersUser.map(m =>
                                    <Grid item xs={12} sm={6} md={6} key={m._id}>
                                        <MinerView miner={m} />
                                    </Grid>
                                )}
                                </Grid>
                            }
                        </div>
                    </Paper>
                </Grid>
            </Grid>

           
        </div>

    )
}