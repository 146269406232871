import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {useState, useEffect} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import QRCode from "react-qr-code";
import axiosInstance from '../redux/axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function PlanPurchasePopUp({open, setOpen, plan, setSelectedPlan}){
    const {user} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [hash, setHash] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);

    const matches = useMediaQuery('(max-width:600px)');
    const address = '0x1f208F6Ce7b30AadF11d1901Ae0540bbFE05cFf1';

    const handleClose = () => {
        setLoading(false);
      };

    useEffect(() => {
        setError('');
        setSuccess('');
    }, [hash])

    useEffect(() => {
        if(success !== ''){
            setError('');
        }
    }, [success])

    function validateTransactionHash(hash) {
        if (hash.startsWith("0x")) {
          if (hash.length === 66) {
            return true;
          }
        }
        return false;
    }

    const confirm = async () => {
        if(hash !== ''){
            if(validateTransactionHash(hash)){
                setLoading(true);
                try{
                    const data = {
                        type: plan?.type,
                        ownerId: user?._id,
                        tx: hash
                    }
                    const response = await axiosInstance.post('miners/'+hash, data);

                    if(response?.data?.success){
                        setLoading(false);
                        setSuccess('Rent for ' + plan?.name + ' is completed. Check dashboard to see your new miner in action!');
                        setTimeout(() => {
                            navigate('/user/miners');
                        } , 6000)
                    }
                
                }catch(err) {
                   if(err?.response?.data){
                        setError(err.response.data)
                    } 
                    setLoading(false);
                }
            }else{
                setError('Invalid transaction hash format');
            }
        }else{
            setError('Fill in the form');
        }
    }

    const copyAddress = () => {
        try{
            const textarea = document.createElement("textarea");
            textarea.value = address;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);

            setCopied(true);

            setTimeout(() => {
                setCopied(false);
            }, 1000)
        }catch{}
          
    }
    
    return(
        <Modal
        data-bs-theme="dark"
        show={open}
        fullscreen={matches}
        onHide={() => {setOpen(false);setSelectedPlan(null)}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Confirmation to rent {plan?.name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>

            {error && <p className="text_error pb-3 pt-1">{error}</p>}
            {success && <p className="text_success pb-3 pt-1">{success}</p>}

            <p className="plan_pop_p">
                Daily income : <span>{plan?.type} USDC</span>
            </p>

            <Form.Group className="mb-3" controlId="popUp.amount">
                <Form.Label>Network</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control type="text" disabled value="ERC20 Base" placeholder="" />
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="popUp.amount">
                <Form.Label>Address to</Form.Label>
                <div style={{display: 'flex', justifyContent: 'center'}} className="pb-3">
                    <QRCode
                        size={156}
                        style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                        value={address}
                        viewBox={`0 0 256 256`}
                    />
                </div>
                <InputGroup hasValidation>    
                    <InputGroup.Text onClick={() => copyAddress()} style={{cursor: 'pointer'}}>{copied ? <DoneIcon style={{color: 'green'}}/> : <ContentCopyIcon/>}</InputGroup.Text>
                    <Form.Control type="string" disabled value={address} placeholder="" />
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="popUp.amount">
                <Form.Label>Rent Amount</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control type="number" disabled value={plan ? plan.price : 0}  placeholder="" />
                    <InputGroup.Text style={{cursor: 'pointer'}}>USDC</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="popUp.hash">
                <Form.Label>Transaction Hash (Txn) Confirmation</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control type="string" value={hash} onChange={(e) => setHash(e.target.value)}  placeholder="" />
                </InputGroup>
                <Form.Text muted>
                    <p onClick={() => window.open('/tutorial','_blank')} className="helper_popup">How to retrieve the Transaction Hash?</p>
                </Form.Text>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1rem'}}>
                <Button style={{color: 'white', borderColor: 'white'}} variant="outlined" onClick={() => {setOpen(false);setSelectedPlan(null)}}>Close</Button>
                <Button className="confirm_btn" variant="contained" onClick={() => confirm()}>Confirm</Button>
            </div>
        </Modal.Footer>
        </Modal>
    )
}