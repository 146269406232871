import { useEffect , useState, useCallback } from 'react';
import axiosInstance from '../../redux/axios';
import PlansUser from '../../components/PlansUser';
import { useDispatch} from 'react-redux';
import { logOutAuth } from '../../redux/reducers/authReducer';
import { useNavigate } from 'react-router-dom';

export default function Miners({user}){
    const [minersUser, setMiners] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getMiners = useCallback(
        async () => {
          try{
            const response = await axiosInstance.get('miners');
   
            if(response.data){
              setMiners(response.data);
            }
          }catch(err){
              if(err.message === "Request failed with status code 401"){
                dispatch(logOutAuth())
                navigate('/login');
              }
          }
        },
        [dispatch, navigate],
      )
      
  
    useEffect(() => {
        getMiners();
    },[getMiners])

    return(
        <>
           <PlansUser minersUser={minersUser}/>
        </>
    )
}