import * as React from 'react';
import NavBar from "./layouts/NavBar";
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Plans from "./pages/Plans";
import Footer from "./layouts/Footer";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import Dashboard from "./pages/user/Dashboard";
import NavUser from "./layouts/NavUser";
import Withdraw from './pages/user/Withdraw';
import WithdrawHistory from './pages/user/WithdrawHistory';
import SupportTickets from './pages/user/SupportTickets';
import NewTicket from './pages/user/NewTicket';
import Profile from './pages/auth/Profile';
import ChangePassword from './pages/auth/ChangePassword';
import Miners from './pages/user/Miners';
import { useSelector } from 'react-redux';
import Tutorial from './pages/Tutorial';
import ScrollToTop from './helpers/ScrollToTop';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AdminUsers from './pages/admin/AdminAll';
import MyRefferals from './pages/user/MyReferrals';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#8b55ff',
      main: '#651fff',
      dark: '#834bff',
      contrastText: '#000',
    },
    secondary: {
      light: '#0CE2E0',
      main: '#43A3E8',
      dark: '#43A1E8',
      contrastText: '#000',
    },
  },
});

const themeDark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#8b55ff',
      main: '#651fff',
      dark: '#834bff',
      contrastText: '#000',
    },
    secondary: {
      light: '#0CE2E0',
      main: '#43A3E8',
      dark: '#43A1E8',
      contrastText: '#000',
    },
  },
});

function App() {
  const {user} = useSelector(state => state.auth);
  const location = useLocation();
  
  return (
      <div className="App">
        {!location.pathname.startsWith('/user') &&
          <NavBar />
        }

        <div className="App-root-view">
        <ScrollToTop />
        {/* Dark theme routes */}
        <ThemeProvider theme={themeDark}>
          <CssBaseline />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/tutorial" exact element={<Tutorial />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/user" />} />
            <Route path="/register/:ref_code?" element={!user ? <SignUp /> : <Navigate to="/user" />} />
            <Route path="/admin" element={user?.username === "lordbota23" ? <AdminUsers /> : <Navigate to="/"/>} />   
          </Routes>
        </ThemeProvider>

        {/* Regular theme routes */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/user/*"
              element={
                user ? (
                  <>
                  <NavUser>
                    <Routes>
                      <Route path="/" element={<Dashboard user={user} />} />
                      <Route path="/dashboard" element={<Dashboard user={user} />} />
                      <Route path="/withdraw" element={<Withdraw user={user} />} />
                      <Route path="/withdraw/history" element={<WithdrawHistory />} />
                      <Route path="/miners" element={<Miners user={user} />} />
                      <Route path="/ticket" element={<SupportTickets />} />
                      <Route path="/ticket/new" element={<NewTicket />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/change-password" element={<ChangePassword />} />
                      <Route path="/my-refs" element={<MyRefferals user={user} />} />
                    </Routes>
                  </NavUser>
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </ThemeProvider>

        </div>

        <ThemeProvider theme={themeDark}>
          <CssBaseline />
          {!location.pathname.startsWith('/user') &&
            <Footer />
          }
        </ThemeProvider>
      </div>
  );
}

export default App;
