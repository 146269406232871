import {useEffect, useCallback,useState} from 'react';
import WHistory from '../../components/withdraw/WHistory';
import Container from '@mui/material/Container';
import axiosInstance from '../../redux/axios';
import { useDispatch} from 'react-redux';
import { logOutAuth } from '../../redux/reducers/authReducer';
import { useNavigate } from 'react-router-dom';

//tx, amount, status, date, userId, wallet
export default function WithdrawHistory(){
    const [withdraws, setWithdraw] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getWithdraws = useCallback(
      async () => {
        try{
            const response = await axiosInstance.get('users/withdraws');

            if(response.data){
                setWithdraw(response.data)
            }
        }catch(err){
            if(err.message === "Request failed with status code 401"){
              dispatch(logOutAuth())
              navigate('/login');
            }
        }
      },
      [dispatch, navigate],
    )
    
    useEffect(() => {
        getWithdraws()
    }, [getWithdraws])
    
    return(
        <div className="dashboard_page">
            <Container>
                <WHistory withdraws={withdraws} setWithdraw={setWithdraw} />
            </Container>
        </div>
    )
}